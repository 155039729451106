:root {
  --page-gap-horizontal: 20px;
  --page-gap-vertical: 30px;
  --section-gap: 20px;
}

.section h2 {
  font-size: 30px;
}

#leadspace {
  grid-gap: var(--section-gap);
  grid-template-columns: 1fr;
  grid-template-areas: "c"
                       "b";
}

#leadspace #logo {
  width: 32px;
  height: 32px;
  position: absolute;
}

#leadspace #introduction h1 {
  font-size: 40px;
}

#leadspace #introduction p {
  font-size: 18px;
}

#leadspace #introduction nav {
  display: none;
}

#leadspace #photo {
  margin-top: calc(var(--page-gap-vertical) * -1);
  margin-left: calc(var(--page-gap-horizontal) * -1);
  width: 100vw;
  height: auto;
  padding-top: 100%;
}

#projects {
  grid-template-columns: 1fr;
}

#magzines, #contacts {
  grid-template-columns: repeat(2, 1fr);
}

#footer {
  font-size: 14px;
}

/*# sourceMappingURL=index.ddb6b0a4.css.map */
