:root {
    --page-gap-horizontal: 20px;
    --page-gap-vertical: 30px;
    --section-gap: 20px;
}

.section {
    h2 {
        font-size: 30px;
    }
}

#leadspace {
    grid-gap: var(--section-gap);
    grid-template-columns: 1fr;
    grid-template-areas:
        'c'
        'b';

    #logo {
        position: absolute;
        width: 32px;
        height: 32px;
    }

    #introduction {
        h1 {
            font-size: 40px;
        }

        p {
            font-size: 18px;
        }

        nav {
            display: none;
        }
    }

    #photo {
        margin-top: calc(var(--page-gap-vertical) * -1);
        margin-left: calc(var(--page-gap-horizontal) * -1);
        width: calc(100vw);
        padding-top: 100%;
        height: auto;
    }
}

#projects {
    grid-template-columns: 1fr;
}

#magzines {
    grid-template-columns: repeat(2, 1fr);
}

#contacts {
    grid-template-columns: repeat(2, 1fr);
}

#footer {
    font-size: 14px;
}
